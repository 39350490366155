<template>
    <div class="w-full h-full">
        
        <!-- If accounts has responded but user had 0 accounts -->
        <div 
            v-if="
                (accounts && accounts.length == 0) ||
                showNewAccount
            ">
            <div class="p-8 overflow-auto max-w-3xl mx-auto ">
                <h1>{{$t('auth.welcome')}}</h1>
                <p class="mb-8">{{$t('auth.welcomeText')}}</p>
                
                <form @submit.prevent="create()">
                    <div class="md:grid grid-cols-2 gap-8">
                        <div class="mb-10">
                            <InputSingleline 
                                v-model="accountNew.name"
                                :required="true"
                                :label="$t('auth.yourCompanyName')"
                                name="name"
                                :showRequiredWarning="false"
                                class="flex-1"
                                :placeholder="$t('auth.yourCompanyName')"
                                ref="autofocus"
                            />
                            
                            <!-- <InputSelect 
                                v-model="accountNew.industry"
                                :required="true"
                                :label="$t('auth.industry')"
                                name="industry"
                                class="flex-1 mb-4"
                                :showRequiredWarning="false"
                                :options="industries"
                            />
                            -->
                            
                            <InputSingleline 
                                v-model="accountNew.contact.email"
                                :label="$t( 'settings.contactDetails.email' )"
                                :name="'email'"
                                :required="true"
                            />
                            
                            <!-- <div class="grid grid-cols-2 gap-8"> -->
                            <InputSingleline 
                                v-model="accountNew.contact.phone"
                                :label="$t( 'settings.contactDetails.phone' )"
                                :required="false"
                                :name="'phone'"
                            />
                            <!-- </div> -->
                            
                            <InputSingleline 
                                v-model="accountNew.contact.webpage"
                                :required="false"
                                :label="$t('auth.companyWebPage')"
                                name="url"
                                :placeholder="$t('auth.companyWebPagePlaceholder')"
                            />
                        </div>
                        <div class="">
                            <InputSingleline 
                                v-model="accountNew.contact.street"
                                :label="$t( 'settings.contactDetails.street' )"
                                :name="'street'"
                                :required="true"
                            />
                            
                            <div class="grid grid-cols-2 gap-8 -my-4">
                                <InputSingleline 
                                    v-model="accountNew.contact.postcode"
                                    :label="$t( 'settings.contactDetails.postcode' )"
                                    :name="'postcode'"
                                    :required="true"
                                />
                                
                                <InputSingleline 
                                    v-model="accountNew.contact.city"
                                    :label="$t( 'settings.contactDetails.city' )"
                                    :name="'city'"
                                    :required="true"
                                />
                            </div>
                            
                            <InputSingleline 
                                v-model="accountNew.contact.country"
                                :label="$t( 'settings.contactDetails.country' )"
                                :name="'country'"
                                class="w-full"
                                :required="true"
                            />
                            
                            <!-- v-if="showPromoCode" -->
                            <InputSingleline 
                                :disabled="showPromoCode ? false : true"
                                :class="showPromoCode ? 'opacity-100' : 'opacity-50'"
                                v-model="accountNew.promoCode"
                                :required="false"
                                :label="$t('auth.promoCode')"
                                name="promoCode"
                                :showRequiredWarning="false"
                                :placeholder="$t('auth.promoCode')"
                                :autofocus="true"
                            />
                            
                        </div>
                    </div>
                    
                    <div class="flex items-center mt-10">
                        <button 
                            :disabled="loading"
                            class="button submit"
                            :class="{'loading' : loading == 'creating'}"
                            type="submit">
                            
                            {{$t('auth.okLetsGo')}}
                        </button>
                         
                        <a 
                            class="link ml-8"
                            v-if="!showPromoCode"
                            @click="showPromoCode = !showPromoCode">
                            
                            {{$t('auth.haveAPromoCode')}}
                        </a>
                     </div>
                </form>
            </div>
        </div>
        <!-- If accounts has responded and user participate on MORE THAN 1 account -->
        <div v-else-if="accounts && accounts.length && accounts.length > 1">
            
            <div 
                v-if="loading == 'setting'"
                class="fixed inset-0 flex items-center justify-center">
                
                <div class="spinner"></div>
            </div>
            <div 
                v-else
                class="p-8 overflow-auto max-w-2xl mx-auto ">
                    
                <h1 class="text-black">{{$t('auth.participatingMoreThanOne')}}</h1>
                <p>{{$t('auth.chooseOneAccount')}}</p>
                
                <div class="">
                    <div 
                        v-for="account in accounts"
                        :key="account.accountId"
                        @click="setAccount(account)"
                        class="cursor-pointer bg-white hover:bg-gray-100 p-4 rounded border mb-2">
                        
                        {{account.name}}
                    </div>  
                </div>
                
                <div 
                    v-if="getUser"
                    class="flex-none m-4 text-center">
                    
                    <div class="mb-2">
                        {{ getUser.attributes.email }}
                    </div>
                
                    <button 
                        @click="signOut()"
                        class="button submit">
                        
                        {{$t('auth.signOut')}}
                    </button>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="fixed inset-0 flex items-center justify-center">
                <div class="spinner"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import AuthMixins from '@/mixins/AuthMixins';

    export default {
        mixins: [
            AuthMixins,
        ],
        
        data(){
            return {
                accountsRaw: null,
                loading: null,
                showPromoCode: false,
                accountNew: {
                    name: null,
                    contact: {
                        webpage: null,
                        email: null,
                        
                    },
                    // industry: null,
                    promoCode: null,
                },
                showNewAccount: false,
            }
        },
        
        computed: {
            getUser(){
                return this.$store.getters.getUser;
            },
            
            getAccount(){
                return this.$store.getters.getAccount;
            },
            
            accounts(){
                if (this.accountsRaw) {
                    return this.accountsRaw.filter( a => {
                        if (a.name) {
                            return true;
                        }
                    });
                }
                
                return null;
            }
        },
        
        methods: {
            async create(){
                try {
                    if (!this.accountNew.name) {
                        throw Error('Need a company name')
                    }
                    
                    this.loading = 'creating';
                    const account = await this.accountsCreate( this.accountNew );
                    // console.log('account response: ', account);
                    this.setAccount( account );
                } 
                catch (e) {
                    console.error(e);
                } 
                finally {
                    this.loading = null;
                }
            },
            
            async setAccount( account ){
                this.loading = 'setting';
                await this.$store.dispatch('setAccount', account);
                this.$router.push({ name: 'dashboard', params: {accountId: account.accountId} });
            }
        },
        
        async mounted(){
            if (localStorage.promoCode) {
                this.accountNew.promoCode = localStorage.promoCode;
                this.showPromoCode = true;
            }
            
            try {
                this.accountsRaw = await this.accountsList();
            } 
            catch (e) {
                this.$notify({ 
                    type: 'error', 
                    title: 'Error', 
                    text: e.message
                });
                
                console.error(e);
                this.$router.push({name: 'signout'});
            }
            
            if (this.accounts.length == 1) {
                // console.log('found one. redirect to this one...');
                this.setAccount( this.accounts[0] );
            }
            
            if (this.getUser) {
                this.accountNew.contact.email = this.getUser.attributes.email;
            }
        }
    }
</script>