// import {Auth} from 'aws-amplify';
import {Auth} from '@aws-amplify/auth';

export default {
    data() {
        return {
            popUpWindow: null,
            // polling: null,
        }
    },
    
    methods: {
        signUp(){
            this.error = null;
            this.loading = true;
            this.email = this.email.toLowerCase().trim();
            
            const data = {
                username: this.email,
                password: this.password,
                attributes: {
                    email: this.email,
                    locale: this.$i18n && this.$i18n.locale ? this.$i18n.locale : 'en',
                }
            };
            
            Auth.signUp(data)
            .then( user => {
                console.log('auth signup response');
                console.log(user);
                analytics.track( 'signUp', user );
                
                this.user = user;
                this.loading = false;
            })
            .catch( err => {
                console.log(err)
                this.error = err.message;
                this.loading = false;
            });
        },
        
        confirmSignUp(){
            this.error = null;
            this.loading = true;
            this.email = this.email.toLowerCase().trim();
            
            // After retrieving the confirmation code from the user
            Auth.confirmSignUp(this.email, this.confirmCode, {
                // Optional. Force user confirmation irrespective of existing alias. By default set to True.
                forceAliasCreation: true    
            })
            .then( data => {
                console.log('confirmSignUp response');
                console.log(data);
                
                this.signIn();
                this.loading = false;
                analytics.track( 'confirmSignUp', this.email );
            })
            .catch( err => {
                console.log(err)
                this.error = err.message;
                this.loading = false;
            });
        },
        
        resendSignUp(){
            this.error = null;
            this.loading = true;
            this.email = this.email.toLowerCase().trim();
            
            Auth.resendSignUp(this.email)
            .then(() => {
                this.loading = false;
                this.$notify({ type: 'success', text: 'The code was resent. Please check your email inbox' });
                analytics.track( 'resendSignUp', this.email );
            })
            .catch( e => {
                this.$notify({ 
                    type: 'error', 
                    title: 'Error', 
                    text: 'There was a problem while resending the confirmation code'
                });
                console.error(e);
                this.error = e.message;
                this.loading = false;
            });
        },
        
        async signIn() {
            try {
                this.error = null;
                this.loading = true;
                this.email = this.email.toLowerCase().trim();
                
                const user = await Auth.signIn({
                    username: this.email,
                    password: this.password,
                });
                
                // console.log('user', user);
                
                const signedInStatus = await this.checkUser();
                console.log('signedInStatus', signedInStatus);
                
                
                // this.loading = false;
                
                if (signedInStatus) {
                    // this.segmentTrack('Logged in', {
                    //     category: 'Account',
                    // });
                    
                    analytics.track( 'signIn', this.email);
                    
                    this.$router.push({name: 'accounts'});
                }
                else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    // console.log('NEW_PASSWORD_REQUIRED');
                    // console.log('user.challengeParam', user.challengeParam);
                    
                    analytics.track( 'signIn', {
                        email: this.email,
                        challengeName: user.challengeName,
                    });
                    
                    this.$router.push({ name: 'newpasswordrequired', params: { user } });
                }
                else {
                    // this.segmentTrack('Logged in failed', {
                    //     category: 'Account',
                    // });
                    
                    analytics.track( 'signIn', {
                        email: this.email,
                        error: 'failed',
                    });
                    
                    this.$router.push({ name: 'signup', params: { error: 'Could not log in after sign up.' } });
                }
            } 
            catch (err) {
                console.log(err);
                this.error = err.message;
                this.loading = false;
            }
        },
        
        async checkUser(){
            try {
                const user = await Auth.currentAuthenticatedUser();
                // console.log('checkUser', user);
                
                // this.$store.state.signedIn = true;
                // 
                this.$store.commit('setUser', user);
                // this.$store.commit('setAuthorizationHeader', jwt);
                // console.log('checkUser in auth', user);
                return user;
            }
            catch (err) {
                console.log('checkUser error', err);
                // console.log('checkUser in auth NOPE');
                // this.$store.state.signedIn = false;
                // this.$store.state.user = null;
                this.$store.commit('setUser', null);
                // this.$store.commit('setAuthorizationHeader', null);
            }
        },
        
        signOut(){
            Auth.signOut()
            .then( data => {
                // this.segmentTrack('Signed Out', {
                //     category: 'Account',
                // });
                
                analytics.track( 'signOut', this.email );
                
                this.$router.push({name: 'signin'});
                
            })
            .catch( err => {
                console.log(err);
                this.error = err.message;
            });
        },
        
        resetPasswordEmail(){
            this.error = null;
            this.loading = false;
            this.email = this.email.toLowerCase().trim();
            
            Auth.forgotPassword(this.email)
            .then(data => {
                console.log('data from forgotPassword', data);
                this.confirmInProgress = data;
                analytics.track( 'resetPasswordEmail', this.email );
            })
            .catch(err => {
                console.log(err);
                this.error = err.message;
            });
        },
        
        resetPasswordSubmit(){
            this.error = null;
            this.email = this.email.toLowerCase().trim();
            this.loading = false;
            
            // Collect confirmation code and new password, then
            Auth.forgotPasswordSubmit(this.email, this.confirmCode, this.password)
            .then(data => {
                console.log('data from forgotPasswordSubmit', data);
                analytics.track( 'resetPasswordSubmit', this.email );
                this.signIn();
            })
            .catch(err => {
                console.log(err);
                this.error = err.message;
                this.loading = false;
            });
        },
        
        newPasswordSubmit(){
            this.error = null;
            this.loading = true;
            this.email = this.user.challengeParam.userAttributes.email;
            
            Auth.completeNewPassword( this.user, this.password)
            .then(user => {
                // at this time the user is logged in if no MFA required
                console.log(user);
                analytics.track( 'newPasswordSubmit', this.email );
                this.signIn();
            })
            .catch(err => {
                console.log(err);
                this.error = err.message;
                this.loading = false;
            });
        },
    }
}
